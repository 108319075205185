const initialValues_Student_Work = {
    ZIPfilename: "",
    parsed_timestamp: "",
  
    vorname: "Maxime",
    nachname: "Musterfrau",
  
    // Kontextangaben
    projektart: "",
    jahr: "",
    semestertype: '',
  
    selecStgType: '',
    selecStgSem: '',
    selecStg: '',
    modul: '',
  
    assignedLehrende: [{}],
    ger_project_titel: "",
    eng_project_titel: "",
  
    ger_project_subtitel: "",
    eng_project_subtitel: "",
  
    selecStgType: { value: "", customValue: "" },
    selecStg: { value: "" },
  
    ger_project_tags: [{}],
    eng_project_tags: [{}],
  
    ger_descr_projekt_short: "",
    eng_descr_projekt_short: "",
  
    ger_descr_projekt_long: "",
    eng_descr_projekt_long: "",
  
    alumni: false,

    url_homepage: "",
    url_homepage_allowance: true,
    
    check_youtube_allowance: true,
    ger_descr_check_youtube_allowance: "",

    url_insta_allowance: true,
    url_behance_allowance: true,
    url_linkedin_allowance: true,
    url_xing_allowance: true,
  
    pdf_plakat_1: null,
    pdf_plakat_1_filename: '',
  
    pdf_plakat_2: null,
    pdf_plakat_2_filename: '',
  
    pdf_dokumentation: null,
    pdf_dokumentation_filename: '',

    img_Web_inputCount: '',
    img_Web_1: null,
    img_Web_1_filename: '',
    img_Web_2: null,
    img_Web_2_filename: '',


    img_Print_inputCount: '',
    img_Print_1: null,
    img_Print_1_filename: '',
    img_Print_2: null,
    img_Print_2_filename: '',
    


    file_1: null,
    file_1_filename: '',

    file_2: null,
    file_2_filename: '',

    ger_feedback: "",
    persondata: [{ filter: '' }] // Start with at least one entry

  };
  
  export default initialValues_Student_Work;
  