// handlebarTemplateUtils.js

export const formatHtml = (htmlContent) => {
    if (!htmlContent) return ""; // Safeguard in case content is null or undefined
    // Convert <p> to double newlines and <br> to single newlines
    return htmlContent
      .replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, "\n") // Handle <p><br></p> as a single line break
      .replace(/<\/p>/g, "\n") // Close paragraph tag with single newline
      .replace(/<p[^>]*>/g, "") // Remove opening paragraph tag
      .replace(/<br\s*\/?>/g, "\n") // Replace <br> with a single newline
      .replace(/<strong>/g, "") // Remove opening <strong> tag
      .replace(/<\/strong>/g, "") // Remove closing </strong> tag
      .replace(/\n+/g, "\n") // Collapse multiple newlines into a single newline
      .trim(); // Remove leading and trailing whitespace and newlines
  };
  