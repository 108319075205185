import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FormSelect = ({ name, labelText, options, onChange, showLabel = true }) => {
  const formik = useFormikContext();
  
  const [field, meta, helpers] = useField(name);

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedOption = options.find(option => option.value === value);
    
    // Check if you're working with objects or just simple values
    if (selectedOption) {
      if (typeof field.value === 'object' && field.value !== null) {
        helpers.setValue(selectedOption);  // Store the full object if required
      } else {
        helpers.setValue(value);  // Otherwise, store just the value
      }
    }

    if (onChange) {
      onChange(e);  // Pass the event back to parent's onChange
    }
  };

  // Apply a different CSS class if the field has a value
  const additionalClass = meta.touched && field.value ? "form-selec-selected" : "";
  const additionalClass_label = meta.touched && field.value ? "formgroup-selected" : "";

  return (
    <FormGroup className={`formgroup ${additionalClass_label}`}>
      {showLabel && 
        <FormLabel htmlFor={name}>{labelText}</FormLabel>}
        <select 
          id={name}
          className={`form-selec ${additionalClass}`}
          value={typeof field.value === 'object' && field.value !== null 
            ? field.value.value // If field value is an object, get its `value` property
            : field.value || '' // Otherwise, use the simple value
          }
          onChange={handleChange}
        >
          <option value="">{labelText}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default FormSelect;
