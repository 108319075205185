import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Extend Quill Clipboard to paste plain text only
const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    // Get plain text from clipboard (without formatting)
    const plainText = (e.clipboardData || window.clipboardData).getData('text/plain');
    const range = this.quill.getSelection();

    // Insert the plain text at the current selection
    this.quill.insertText(range.index, plainText);
    this.quill.setSelection(range.index + plainText.length); // Move cursor after the pasted content
  }
}

// Register the modified clipboard
Quill.register('modules/clipboard', PlainClipboard, true);

const QuillEditorStaff = ({ value, onChange, fieldname, maxChars }) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setEditorHtml(value);
    setCharCount(value ? value.replace(/<(.|\n)*?>/g, '').length : 0); // Update initial character count
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    const currentLength = editor.getLength();
    const cleanContent = content.replace(/<p><br><\/p>/g, ''); // Clean empty paragraph tags

    if (cleanContent === '') {
      setCharCount(0);
      onChange(''); // Notify parent that the content is empty
    } else if (currentLength <= maxChars + 1) {
      setEditorHtml(cleanContent);
      setCharCount(currentLength - 1); // Update character count (excluding the extra newline Quill adds)
      onChange(cleanContent);
    } else {
      const truncatedContent = cleanContent.substring(0, maxChars);
      setEditorHtml(truncatedContent);
      setCharCount(maxChars); // Update character count
      onChange(truncatedContent);
      console.log("Character limit reached");
    }
  };

  const charCountClass = charCount > maxChars ? 'quillcount limit-reached' : 'quillcount';

  return (
    <div>
      <ReactQuill
        className="quillEditor"
        value={editorHtml}
        onChange={handleChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
          ],
          clipboard: {
            matchVisual: false,  // Don't add formatting when pasting
          },
        }}
      />
      <div className={charCountClass}>
        Anzahl Zeichen: {charCount} / {maxChars}
      </div>
    </div>
  );
};

export default QuillEditorStaff;
